import React from 'react';
import styles from './Events.module.css';
import Card from '../UI/Card';
import { eventsData } from '../../data/events';

interface Event {
  id: string;
  title: string;
  date: string;
  location: string;
  description: string;
  url: string;
  imageUrl: string;
}

const Events: React.FC = () => {
  return (
    <div className={styles.events}>
      <div className={styles.grid}>
        {eventsData.map((event: Event) => (
          <Card key={event.id} className={styles.card}>
            <div className={styles.eventImage}>
              <img src={event.imageUrl} alt={event.title} />
              <div className={styles.eventDate}>{event.date}</div>
            </div>
            <h3 className={styles.eventTitle}>{event.title}</h3>
            <div className={styles.eventLocation}>{event.location}</div>
            <p className={styles.eventDescription}>{event.description}</p>
            <a 
              href={event.url} 
              target="_blank" 
              rel="noopener noreferrer"
              className={styles.eventLink}
            >
              詳細を見る &rarr;
            </a>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default Events; 