import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Header.module.css';
import Logo from '../Common/Logo';

const Header: React.FC = () => {
  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <div className={styles.logo}>
          <Link to="/top">
            <Logo />
          </Link>
        </div>
        <nav className={styles.nav}>
          <ul>
            <li><a href="#about">About</a></li>
            <li><a href="#puzzles">Puzzles</a></li>
            <li><a href="#events">Events</a></li>
            <li><a href="#tools">Tools</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header; 