import React from 'react';
import styles from './Logo.module.css';

const Logo: React.FC = () => {
  return (
    <div className={styles.logo}>
      <div className={styles.cube}>
        <div className={styles.face}></div>
        <div className={styles.face}></div>
        <div className={styles.face}></div>
      </div>
      <span className={styles.text}>LogicPuzzle<span className={styles.accent}>.app</span></span>
    </div>
  );
};

export default Logo; 