export interface PuzzleSite {
  id: string;
  name: string;
  description: string;
  url: string;
  imageUrl: string;
}

export const puzzleSitesData: PuzzleSite[] = [
  {
    id: 'nikoli',
    name: 'ニコリ',
    description: '数独やスリザーリンクなど、多くの人気パズルを生み出した老舗パズルメーカー。オンラインでもパズルが楽しめます。',
    url: 'https://www.nikoli.co.jp/',
    imageUrl: 'https://via.placeholder.com/400x200?text=ニコリ'
  },
  {
    id: 'puzzle-communication-nikoli',
    name: 'パズルコミュニケーションニコリ',
    description: 'ニコリが発行するパズル雑誌。様々な論理パズルが掲載されています。',
    url: 'https://www.nikoli.co.jp/ja/publication/magazine/',
    imageUrl: 'https://via.placeholder.com/400x200?text=パズルコミュニケーションニコリ'
  },
  {
    id: 'simon-tatham',
    name: 'Simon Tatham\'s Puzzle Collection',
    description: '無料で遊べる様々な論理パズルのコレクション。ブラウザで手軽に遊べます。',
    url: 'https://www.chiark.greenend.org.uk/~sgtatham/puzzles/',
    imageUrl: 'https://via.placeholder.com/400x200?text=Simon+Tatham'
  },
  {
    id: 'puzzle-baron',
    name: 'Puzzle Baron',
    description: '数独、クロスワード、ロジックパズルなど様々なパズルが楽しめるサイト。',
    url: 'https://www.puzzlebaron.com/',
    imageUrl: 'https://via.placeholder.com/400x200?text=Puzzle+Baron'
  },
  {
    id: 'conceptis',
    name: 'Conceptis Puzzles',
    description: '数独、ぬりかべ、スリザーリンクなど、様々な論理パズルを提供するサイト。',
    url: 'https://www.conceptispuzzles.com/',
    imageUrl: 'https://via.placeholder.com/400x200?text=Conceptis+Puzzles'
  },
  {
    id: 'puzzle-japan',
    name: 'パズルジャパン',
    description: '日本パズル協会が運営するサイト。パズルの大会情報やパズル作家の情報が掲載されています。',
    url: 'https://puzzlejapan.org/',
    imageUrl: 'https://via.placeholder.com/400x200?text=パズルジャパン'
  }
]; 