import React from 'react';
import styles from './Footer.module.css';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.top}>
          <div className={styles.info}>
            <h3>Logic Puzzle App</h3>
            <p>論理パズルを楽しむための総合サイト</p>
          </div>
          <div className={styles.links}>
            <div className={styles.linkGroup}>
              <h4>サイト</h4>
              <ul>
                <li><Link to="/top">ホーム</Link></li>
                <li><a href="#about">About</a></li>
                <li><a href="#puzzles">パズルサイト</a></li>
                <li><a href="#events">イベント</a></li>
              </ul>
            </div>
            <div className={styles.linkGroup}>
              <h4>ツール</h4>
              <ul>
                <li><a href="#tools">パズルツール</a></li>
                <li><a href="/">旧サイト</a></li>
              </ul>
            </div>
            <div className={styles.linkGroup}>
              <h4>お問い合わせ</h4>
              <ul>
                <li><a href="#contact">お問い合わせフォーム</a></li>
                <li><a href="https://twitter.com/logicpuzzleapp" target="_blank" rel="noopener noreferrer">Twitter</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.bottom}>
          <p>&copy; {currentYear} Logic Puzzle App All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 