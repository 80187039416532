import React from 'react';
import logo from '../../static/logo.webp';

export const Header = () => {
  return (
    <header className="header">
      <div className="header-content">
        <div className="header-logo">
          <a href="/" className="header-logo-link">
            <img src={logo} alt="LogicPuzzle.App Logo" className="header-logo-image" />
            <h1 className="header-title">
              <span className="header-title-text">LogicPuzzle.App</span>
            </h1>
          </a>
        </div>
      </div>
    </header>
  );
};
