import React from 'react';
import styles from './GeometricElements.module.css';

interface GeometricElementsProps {
  type?: 'background' | 'accent';
  position?: 'top' | 'bottom' | 'left' | 'right';
  color?: string;
}

type CustomCSSProperties = React.CSSProperties & {
  '--element-color'?: string;
};

const GeometricElements: React.FC<GeometricElementsProps> = ({ 
  type = 'background',
  position = 'top',
  color
}) => {
  const className = `${styles.geometricElements} ${styles[type]} ${styles[position]}`;

  const customStyle: CustomCSSProperties | undefined = color 
    ? { '--element-color': color }
    : undefined;
  
  return (
    <div 
      className={className} 
      style={customStyle}
    >
      <div className={styles.element}></div>
      <div className={styles.element}></div>
      <div className={styles.element}></div>
      <div className={styles.element}></div>
      <div className={styles.element}></div>
    </div>
  );
};

export default GeometricElements; 