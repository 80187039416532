import React from 'react';
import styles from './PuzzleSites.module.css';
import Card from '../UI/Card';
import { puzzleSitesData } from '../../data/puzzleSites';

const PuzzleSites: React.FC = () => {
  return (
    <div className={styles.puzzleSites}>
      <div className={styles.grid}>
        {puzzleSitesData.map((site, index) => (
          <Card key={index} className={styles.card}>
            <div className={styles.siteImage}>
              <img src={site.imageUrl} alt={site.name} />
            </div>
            <h3 className={styles.siteName}>{site.name}</h3>
            <p className={styles.siteDescription}>{site.description}</p>
            <a 
              href={site.url} 
              target="_blank" 
              rel="noopener noreferrer"
              className={styles.siteLink}
            >
              サイトを訪れる &rarr;
            </a>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default PuzzleSites; 