import { ThemeProvider } from "@emotion/react";
import { createTheme, ThemeOptions } from "@mui/material/styles";
import { getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Route, Routes } from "react-router";
import { BrowserRouter, Navigate } from "react-router-dom";
import { makeSessionCookie } from "./lib/auth-api/makeSessionCookie";
import { Login } from "./component/Login";
import { Logout } from "./component/Logout";
import { Refresh } from "./component/Refresh";
import { Signup } from "./component/Signup";
import TopPage from "./component/TopPage";
import "./css/App.css";
import { PasswordReset } from "./component/PasswordReset";
import { LoginOrSignup } from "./component/LoginOrSignup";
import NewTopPage from "./new-lp/components/TopPage";

// CSSの読み込み
import "./new-lp/styles/globals.css";

export const themeOptions: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#212121",
    },
    secondary: {
      main: "#f50057",
    },
  },
};

const theme = createTheme(themeOptions);

function App() {
  const auth = getAuth();
  const [cookieCreated, setCookieCreated] = useState(false);
  const [user, loading] = useAuthState(auth);
  useEffect(() => {
    //サイトを訪れてログインした際に一度だけcookieを作成する
    if (!loading && user !== null && user !== undefined && !cookieCreated) {
      const token = user
        .getIdToken(true)
        .then((token) => {
          return makeSessionCookie(token);
        })
        .then((cookie) => {
          setCookieCreated(true);
        });
    }
  }, [loading, user]);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<TopPage />} />
          <Route path="/top" element={<NewTopPage />} />
          <Route
            path="/auth/:callback"
            element={
              <LoginOrSignup auth={auth} user={user} loading={loading} />
            }
          />
          <Route
            path="/login/:callback"
            element={<Login auth={auth} user={user} loading={loading} />}
          />
          <Route
            path="/signup/:callback"
            element={<Signup auth={auth} user={user} loading={loading} />}
          />
          <Route path="/logout/:callback" element={<Logout />} />
          <Route
            path="/refresh/:callback"
            element={<Refresh cookieCreated={cookieCreated} auth={auth} />}
          />
          <Route
            path="/password-reset/:callback"
            element={
              <PasswordReset auth={auth} user={user} loading={loading} />
            }
          />
          <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
