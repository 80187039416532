import React from 'react';
import styles from './Section.module.css';
import GeometricElements from '../Common/GeometricElements';

interface SectionProps {
  id?: string;
  title?: string;
  subtitle?: string;
  children: React.ReactNode;
  background?: 'light' | 'dark' | 'primary';
  withDecoration?: boolean;
  centered?: boolean;
}

const Section: React.FC<SectionProps> = ({
  id,
  title,
  subtitle,
  children,
  background = 'light',
  withDecoration = false,
  centered = false
}) => {
  const sectionClasses = `${styles.section} ${styles[background]} ${centered ? styles.centered : ''}`;

  return (
    <section id={id} className={sectionClasses}>
      {withDecoration && <GeometricElements position="top" />}
      <div className={styles.container}>
        {(title || subtitle) && (
          <div className={styles.header}>
            {title && <h2 className={styles.title}>{title}</h2>}
            {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
          </div>
        )}
        <div className={styles.content}>
          {children}
        </div>
      </div>
    </section>
  );
};

export default Section; 