import React from 'react';
import styles from './Tools.module.css';
import Card from '../UI/Card';
import { toolsData } from '../../data/tools';

interface Tool {
  id: string;
  name: string;
  description: string;
  url: string;
  iconUrl: string;
}

const Tools: React.FC = () => {
  return (
    <div className={styles.tools}>
      <div className={styles.grid}>
        {toolsData.map((tool: Tool) => (
          <Card key={tool.id} className={styles.card} onClick={() => window.open(tool.url, '_blank')}>
            <div className={styles.toolIcon}>
              <img src={tool.iconUrl} alt={tool.name} />
            </div>
            <div className={styles.toolContent}>
              <h3 className={styles.toolName}>{tool.name}</h3>
              <p className={styles.toolDescription}>{tool.description}</p>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default Tools; 