import React from 'react';
import Layout from './Layout/Layout';
import Section from './UI/Section';
import PuzzleSites from './Home/PuzzleSites';
import Events from './Home/Events';
import Tools from './Home/Tools';

const TopPage: React.FC = () => {
  return (
    <Layout>
      <Section 
        id="hero" 
        background="primary" 
        centered
        withDecoration
      >
        <div style={{ textAlign: 'center', padding: '4rem 0' }}>
          <h1 style={{ fontSize: '3.5rem', marginBottom: '1.5rem', color: 'var(--color-text-light)' }}>
            Logic Puzzle App
          </h1>
          <p style={{ fontSize: '1.5rem', maxWidth: '800px', margin: '0 auto 2rem', color: 'var(--color-text-light)', opacity: 0.8 }}>
            論理パズルを楽しむための総合サイト
          </p>
          <a 
            href="/" 
            className="button" 
            style={{ 
              display: 'inline-block', 
              padding: '1rem 2rem', 
              fontSize: '1.2rem',
              marginTop: '1rem'
            }}
          >
            旧サイトを見る
          </a>
        </div>
      </Section>

      <Section 
        id="about" 
        title="Logic Puzzle Appについて" 
        subtitle="論理パズルの魅力を多くの人に伝えるためのプラットフォームです"
        background="light"
        centered
      >
        <div style={{ maxWidth: '800px', textAlign: 'center' }}>
          <p>
            Logic Puzzle Appは、論理パズルを愛する人々のためのコミュニティサイトです。
            数独、ナンプレ、お絵かきロジック、スリザーリンクなど、様々な論理パズルの情報を提供しています。
          </p>
          <p>
            パズルを解くためのツールや、パズル関連のイベント情報、おすすめのパズルサイトなど、
            論理パズルに関する様々な情報を集約しています。
          </p>
        </div>
      </Section>

      <Section 
        id="puzzles" 
        title="おすすめパズルサイト" 
        subtitle="論理パズルが楽しめるウェブサイトをご紹介します"
        background="dark"
        withDecoration
      >
        <PuzzleSites />
      </Section>

      <Section 
        id="events" 
        title="パズル関連イベント" 
        subtitle="パズル大会やイベントの最新情報"
        background="light"
      >
        <Events />
      </Section>

      <Section 
        id="tools" 
        title="パズルツール" 
        subtitle="パズルを解くための便利なツール集"
        background="dark"
        withDecoration
      >
        <Tools />
      </Section>

      <Section 
        id="contact" 
        title="お問い合わせ" 
        subtitle="ご質問やご意見がありましたら、お気軽にお問い合わせください"
        background="primary"
        centered
      >
        <div style={{ maxWidth: '600px', margin: '0 auto' }}>
          <form style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <div>
              <label htmlFor="name" style={{ display: 'block', marginBottom: '0.5rem', color: 'var(--color-text-light)' }}>お名前</label>
              <input 
                type="text" 
                id="name" 
                style={{ 
                  width: '100%', 
                  padding: '0.75rem', 
                  borderRadius: '4px', 
                  border: 'none' 
                }} 
              />
            </div>
            <div>
              <label htmlFor="email" style={{ display: 'block', marginBottom: '0.5rem', color: 'var(--color-text-light)' }}>メールアドレス</label>
              <input 
                type="email" 
                id="email" 
                style={{ 
                  width: '100%', 
                  padding: '0.75rem', 
                  borderRadius: '4px', 
                  border: 'none' 
                }} 
              />
            </div>
            <div>
              <label htmlFor="message" style={{ display: 'block', marginBottom: '0.5rem', color: 'var(--color-text-light)' }}>メッセージ</label>
              <textarea 
                id="message" 
                rows={5} 
                style={{ 
                  width: '100%', 
                  padding: '0.75rem', 
                  borderRadius: '4px', 
                  border: 'none',
                  resize: 'vertical'
                }}
              ></textarea>
            </div>
            <button 
              type="submit" 
              style={{ 
                alignSelf: 'flex-start', 
                marginTop: '1rem',
                padding: '0.75rem 2rem'
              }}
            >
              送信する
            </button>
          </form>
        </div>
      </Section>
    </Layout>
  );
};

export default TopPage; 