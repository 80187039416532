export interface Event {
  id: string;
  title: string;
  date: string;
  location: string;
  description: string;
  url: string;
  imageUrl: string;
}

export const eventsData: Event[] = [
  {
    id: 'world-puzzle-championship-2023',
    title: '世界パズル選手権 2023',
    date: '2023年10月15日〜22日',
    location: 'イタリア・ローマ',
    description: '世界各国からパズル愛好家が集まり、様々な論理パズルで競い合う国際大会です。',
    url: 'https://www.worldpuzzle.org/',
    imageUrl: 'https://via.placeholder.com/400x200?text=世界パズル選手権'
  },
  {
    id: 'japan-puzzle-championship-2023',
    title: '日本パズル選手権 2023',
    date: '2023年7月8日〜9日',
    location: '東京・秋葉原',
    description: '日本最大のパズル大会。予選を勝ち抜いた猛者たちによる決勝戦が行われます。',
    url: 'https://puzzlejapan.org/',
    imageUrl: 'https://via.placeholder.com/400x200?text=日本パズル選手権'
  },
  {
    id: 'puzzle-festival-2023',
    title: 'パズルフェスティバル 2023',
    date: '2023年11月3日〜5日',
    location: '大阪・梅田',
    description: 'パズル作家によるワークショップや新作パズルの展示、パズル関連グッズの販売などが行われるイベントです。',
    url: 'https://example.com/puzzle-festival',
    imageUrl: 'https://via.placeholder.com/400x200?text=パズルフェスティバル'
  },
  {
    id: 'sudoku-grand-prix-2023',
    title: '数独グランプリ 2023',
    date: '2023年9月16日',
    location: 'オンライン',
    description: '世界中の数独愛好家が参加できるオンライン大会。様々な難易度の数独に挑戦できます。',
    url: 'https://example.com/sudoku-grand-prix',
    imageUrl: 'https://via.placeholder.com/400x200?text=数独グランプリ'
  }
]; 