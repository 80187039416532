import React from 'react';
import "../css/App.css";
import honeyLogo from "../static/honey.png";
import igLogo from "../static/ig_400x400.jpg";
import puzsqLogo from "../static/puzsq.jpg";
import puzzlePediaLogo from "../static/puzzle-pedia.png";
import ruleElementLogo from "../static/rule_element.jpg";
import fffPuzzleJourneyLogo from "../static/fffs_Puzzle_Journey.001.png";
import creationLogo from "../static/24hcreation.png";
import parallelLogo from "../static/parallel-puzzle-ii.png";
import tpoLogo from "../static/tpo.png";
import { Header } from "./common/Header";

interface CardProps {
  image: string;
  alt: string;
  title: string;
  description: string;
  link: string;
}

const Card: React.FC<CardProps> = ({ image, alt, title, description, link }) => {
  const handleClick = () => {
    window.open(link);
  };

  return (
    <div
      className="card"
      onClick={handleClick}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          e.preventDefault();
          handleClick();
        }
      }}
      role="button"
      tabIndex={0}
      aria-label={`Visit ${title}`}
    >
      <div className="card-media">
        <img src={image} alt={alt} />
      </div>
      <div className="card-content-container">
        <div className="card-content">
          <h3 className="card-title">{title}</h3>
          <p className="card-description">{description}</p>
        </div>
        <div className="card-actions">
          <span className="card-button">
            <span>Visit</span>
          </span>
        </div>
      </div>
    </div>
  );
};

function TopPage() {
  return (
    <>
      <Header />
      <section className="hero">
        <div className="hero-content">
          <h1 className="hero-title">Welcome to the World of Logic</h1>
          <p className="hero-description">
            Enjoy puzzles together.
          </p>
        </div>
      </section>

      <section className="announcements">
        <div className="announcements-content">
          <h2 className="announcements-title">News</h2>
          <div className="announcements-list">
            <div className="announcement-item">
              <span className="announcement-date">2024.03.26</span>
              <p className="announcement-text">
                <a
                  href="https://x.com/logicpuzzle_app/status/1904857984250806286"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="announcement-link"
                >
                  LogicPuzzle.App logo has been updated
                </a>
              </p>
            </div>
            <div className="announcement-item">
              <span className="announcement-date">2024.03.18</span>
              <p className="announcement-text">
                <a
                  href="https://x.com/logicpuzzle_app/status/1901959380897267934"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="announcement-link"
                >
                  Registration for Tag Puzzle Open 2025 re-event is now open
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <h2 className="section-title" style={{ marginTop: '2rem' }}>Puzzle Contents</h2>
        <div className="grid">
          <Card
            image={puzsqLogo}
            alt="puzsq logo"
            title="Puzzle Square"
            description="One of the largest logic puzzle posting sites in Japan"
            link="https://puzsq.logicpuzzle.app/"
          />
          <Card
            image={igLogo}
            alt="instructionless grid"
            title="Instructionless Grid"
            description="A puzzle site by five creators that presents 101 instructionless puzzles"
            link="https://ig.logicpuzzle.app/"
          />
          <Card
            image={puzzlePediaLogo}
            alt="puzzle pedia"
            title="Puzzle Pedia"
            description="A collection of information about logic puzzles in Japanese"
            link="https://scrapbox.io/puzzle-pedia/"
          />
          <Card
            image={ruleElementLogo}
            alt="rule element"
            title="Rule Element"
            description="A project to break down and arrange the rules of logic puzzles into elements"
            link="https://scrapbox.io/puzzle-pedia/%E3%83%AB%E3%83%BC%E3%83%AB%E8%A6%81%E7%B4%A0"
          />
          <Card
            image={honeyLogo}
            alt="honey island"
            title="Honey View"
            description="A visual tool for honey islands puzzle"
            link="https://honeyis.land"
          />
          <Card
            image={fffPuzzleJourneyLogo}
            alt="fff's puzzle journey"
            title="fff's Puzzle Journey"
            description="An online puzzle magazine"
            link="https://note.com/fffpuzzle/n/nb462648c4d3b"
          />
          <Card
            image={creationLogo}
            alt="24hours creation"
            title="The 24 Hours World"
            description="A puzzle event created within 24+α hours"
            link="https://24h-creation.logicpuzzle.app/"
          />
          <Card
            image={parallelLogo}
            alt="Parallel Puzzle II"
            title="Parallel Puzzle II"
            description="A puzzle event themed on parallel worlds"
            link="https://parallel-puzzle-ii.logicpuzzle.app/"
          />
          <Card
            image={tpoLogo}
            alt="Tag Puzzle Open 2025"
            title="Tag Puzzle Open 2025"
            description="An offline puzzle event for teams of two"
            link="https://tag-puzzle-open-2025.logicpuzzle.app/"
          />
        </div>
      </div>
      <div className="footer">
        <div className="footer-content">
          <p>Copyright © Langue-de-chat.LLC 2022-2025.</p>
        </div>
      </div>
    </>
  );
}

export default TopPage;
