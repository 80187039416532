export interface Tool {
  id: string;
  name: string;
  description: string;
  url: string;
  iconUrl: string;
}

export const toolsData: Tool[] = [
  {
    id: 'sudoku-solver',
    name: '数独ソルバー',
    description: '数独パズルを解くためのツール。難しいパズルも瞬時に解けます。',
    url: '/tools/sudoku-solver',
    iconUrl: 'https://via.placeholder.com/60x60?text=数独'
  },
  {
    id: 'nonogram-solver',
    name: 'お絵かきロジックソルバー',
    description: 'お絵かきロジック（ノノグラム）を解くためのツール。',
    url: '/tools/nonogram-solver',
    iconUrl: 'https://via.placeholder.com/60x60?text=ノノグラム'
  },
  {
    id: 'slitherlink-solver',
    name: 'スリザーリンクソルバー',
    description: 'スリザーリンクパズルを解くためのツール。',
    url: '/tools/slitherlink-solver',
    iconUrl: 'https://via.placeholder.com/60x60?text=スリザーリンク'
  },
  {
    id: 'puzzle-generator',
    name: 'パズル作成ツール',
    description: '様々な論理パズルを自動生成するツール。難易度も調整できます。',
    url: '/tools/puzzle-generator',
    iconUrl: 'https://via.placeholder.com/60x60?text=パズル作成'
  },
  {
    id: 'puzzle-printer',
    name: 'パズル印刷ツール',
    description: 'パズルを印刷用にフォーマットするツール。サイズや難易度を選べます。',
    url: '/tools/puzzle-printer',
    iconUrl: 'https://via.placeholder.com/60x60?text=印刷'
  },
  {
    id: 'puzzle-timer',
    name: 'パズルタイマー',
    description: 'パズルを解く時間を計測するためのシンプルなタイマーツール。',
    url: '/tools/puzzle-timer',
    iconUrl: 'https://via.placeholder.com/60x60?text=タイマー'
  }
]; 